import { Component } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import autoBind from 'react-autobind';
import classNames from 'classnames/bind';
import isEqual from 'lodash-es/isEqual';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import NavBar from '../SentimentStories/NavBar';

import withRouter from '../../../../decorators/withRouter';
import convertPermalinkToPath from '../../../../helpers/urlHelpers';

import SentimentsSvc from '../../../../services/dbServices/SentimentsSvc';
import EntitiesSvc from '../../../../services/dbServices/EntitiesSvc';
import { sentimentNavBar } from '../../../../data/directory/locations/locationsSentiment';
import { getAverageSentimentText } from '../../../../helpers/sentimentHelpers';
import { entityType } from '../../../../data/sentimentAnalysis';
import { SILVER } from '../../../../data/permissions';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

class LocationBySentiment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storiesNavBar: [],
      selectedNavValue: 'Very Positive',
      isSubLocationLoading: true,
      subLocationsList: [],
    };
    autoBind(this);
  }

  componentDidMount() {
    this.generateSubLocationsData();
    this.setState({
      storiesNavBar: sentimentNavBar
        .filter((f) => f.value !== 'all')
        .map((f) => ({
          ...f,
          active: f.value === 'very_positive',
        })),
    });
  }

  componentDidUpdate(prevProps) {
    const { selectedLocation } = this.props;
    if (!isEqual(selectedLocation, prevProps.selectedLocation)) {
      this.generateSubLocationsData();
    }
  }

  async onItemClick(e, item) {
    const { history } = this.props;
    if (item.permalink) {
      return;
    }

    e.preventDefault();
    EntitiesSvc.getEntityPermalink({
      entityId: item.entity_id,
      entityType: item.entity_type,
    }).then(({ permalink }) => {
      if (permalink) {
        item.permalink = permalink;
        history.push(convertPermalinkToPath(permalink));
      }
    });
  }

  onTabChange({ id, name }) {
    this.setState(({ storiesNavBar }) => ({
      storiesNavBar: storiesNavBar.map((tab) => ({
        ...tab,
        active: tab.id === id,
      })),
      selectedNavValue: name,
    }), () => {
      this.generateSubLocationsData();
    });
  }

  async callListingsAPI(params) {
    try {
      const { data } = await SentimentsSvc.getListingsSentiments(params);
      return data;
    } catch (e) {
      console.log(e);
    }
    return {};
  }

  async generateSubLocationsData() {
    const { selectedNavValue } = this.state;
    const {
      selectedMainTimePeriod, slugType, topic, hasSilverAccess, selectedLocation,
    } = this.props;

    if (!hasSilverAccess) return;

    const params = {
      period: selectedMainTimePeriod,
      selection_mode: 'children',
      per_page: 5,
      sentiment_filter_mode: 'categories',
      sentiment_categories: selectedNavValue,
    };

    if (selectedLocation) {
      params.entity_type = selectedLocation.entity_type;
      params.entity_id = selectedLocation.entity_id;
    } else {
      params.entity_type = entityType[slugType];
      params.entity_id = topic.id;
    }

    this.setState({
      isSubLocationLoading: true,
    });
    const data = await this.callListingsAPI(params);
    const subLocationsList = (data.list || []).map((list) => {
      const sentimentNumber = (list.sentiment || '').replace('%', '');
      return {
        ...list,
        ...getAverageSentimentText(sentimentNumber),
      };
    });
    this.setState({
      subLocationsList,
      isSubLocationLoading: false,
    });
  }

  render() {
    const { hasSilverAccess, selectedLocation } = this.props;
    const { storiesNavBar, isSubLocationLoading, subLocationsList } = this.state;

    return (
      <div className={cx('location_by_sentiment', { no_access: !hasSilverAccess })}>
        <div className={Styles.by_sentiment_title}>
          Top locations
          {selectedLocation ? ` for ${selectedLocation.entity_name} ` : ' '}
          by sentiment
        </div>
        <NavBar
          className={Styles.custom_nevbar_design}
          tabs={storiesNavBar}
          onChange={this.onTabChange}
          tablet={false}
        />
        {
          !hasSilverAccess && (
            <div className={Styles.sentiment_upgrade}>
              <a
                className={Styles.pricing_link}
                href="/pricing"
                target="_blank"
                rel="noopener noreferrer"
                onClick={this.sendUpgradeAction}
              >
                Upgrade
              </a>
              &nbsp;to see stories by sentiment category.
            </div>
          )
        }
        <div className={Styles.by_sentiment_location_title}>
          States
        </div>
        <div className={Styles.map_sentiment_rows}>
          {!isSubLocationLoading && !subLocationsList.length && (
            <div className={Styles.map_sentiment_no_data}>
              No recent sentiment data is available
            </div>
          )}
          {subLocationsList.map((list) => (
            <MapSentimentRow
              key={list.entity_id}
              list={list}
              onItemClick={this.onItemClick}
            />
          ))}
        </div>
      </div>
    );
  }
}

const MapSentimentRow = ({ list }) => {
  const {
    entity_name: name, sentiment, icon, type, permalink,
  } = list;
  const history = useHistory();

  const onLinkClick = async (e) => {
    if (permalink) {
      return;
    }

    e.preventDefault();
    EntitiesSvc.getEntityPermalink.then(({ permalink }) => {
      list.permalink = permalink;
      history.push(convertPermalinkToPath(permalink));
    });
  };

  return (
    <Link
      to={permalink ? convertPermalinkToPath(permalink) : ''}
      onClick={onLinkClick}
      className={Styles.map_sentiment_row}
    >
      <div className={Styles.map_sentiment_text}>
        {name}
      </div>
      <div className={cx('map_sentiment_value', type)}>
        <FontAwesomeIcon icon={icon} />
        {sentiment}
      </div>
    </Link>
  );
};

const mapStateToProps = ({ subscriptions, locationsSentiment }) => {
  const { permissions } = subscriptions;
  const hasSilverAccess = permissions.access_levels.includes(SILVER);
  return {
    hasSilverAccess,
    selectedMainTimePeriod: locationsSentiment.selectedMainTimePeriod,
    selectedLocation: locationsSentiment.selectedLocation,
  };
};

export default connect(mapStateToProps)(withRouter(LocationBySentiment));
