import { Component } from 'react';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import classNames from 'classnames/bind';

import WorldChart from './WorldChart';
import LocationBySentiment from '../LocationBySentiment';

import SentimentsSvc from '../../../../services/dbServices/SentimentsSvc';
import { generateListingsChartData } from '../../../../helpers/sentimentHelpers';
import { entityType } from '../../../../data/sentimentAnalysis';
import {
  CONTINENT,
} from '../../../../data/directory/topicTypes';
import { SENTIMENT_TOOLTIP_VALUE } from '../../../../data/directory/locations/locationsSentiment';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

class SentimentWorldChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainChartData: [],
      chartData: {
        x: [],
        y: [],
      },
      isChartLoading: true,
      chartScope: '',
    };

    autoBind(this);
  }

  componentDidMount() {
    this.getContinentScopeName();
    this.generateChartData();
  }

  componentDidUpdate(prevProps) {
    const { selectedMainTimePeriod } = this.props;
    if (selectedMainTimePeriod !== prevProps.selectedMainTimePeriod) {
      this.generateChartData();
    }
  }

  getContinentScopeName() {
    const { topic, parents, isLocationsTab } = this.props;
    let chartScope = 'world';
    if (!isLocationsTab) {
      chartScope = 'world';
    } else if (!parents.length) {
      chartScope = topic.name;
    } else {
      chartScope = (parents.find((f) => f.includes(':Continent:')) || '').split(':')[3].replace(/["\\]/g, '');
    }
    this.setState({
      chartScope: chartScope.toLowerCase(),
    });
  }

  async callListingsAPI(params) {
    try {
      const { data } = await SentimentsSvc.getListingsSentiments(params);
      const newData = data?.list?.map((obj) => ({
        ...obj,
        ...(!obj.sentiment && obj.sentiment !== 0
            && { sentiment: SENTIMENT_TOOLTIP_VALUE.toString() }),
      }));
      return { ...data, list: newData };
    } catch (e) {
      console.log(e);
    }
    return { list: [] };
  }

  async callRecursiveListingsAPI(params, oldData = []) {
    const data = await this.callListingsAPI(params);
    const newData = [...oldData, ...data.list];
    if (data.next_page_token) {
      return this.callRecursiveListingsAPI({
        ...params,
        next_page_token: data.next_page_token,
      }, newData);
    }
    return newData;
  }

  async callSubcontinentsAPI() {
    const { topic, selectedMainTimePeriod } = this.props;
    const allDataResponse = await Promise
      .all(topic.subcontinents.map((entity) => this.callListingsAPI({
        entity_type: 'SubContinent',
        period: selectedMainTimePeriod,
        selection_mode: 'children',
        entity_id: entity.id,
      })));

    const allData = [];
    for (let i = 0; i < allDataResponse.length; i += 1) {
      const { list } = allDataResponse[i];
      allData.push(...list);
    }

    return allData;
  }

  async generateChartData() {
    const {
      selectedMainTimePeriod, isLocationsTab,
      slugType, topic,
    } = this.props;
    const params = {
      entity_type: 'Country',
      period: selectedMainTimePeriod,
    };
    if (isLocationsTab) {
      params.entity_type = entityType[slugType];
      params.entity_id = topic.id;
      params.selection_mode = 'children';
    }

    this.setState({
      isChartLoading: true,
    });

    let data;
    if (slugType === CONTINENT) {
      data = await this.callSubcontinentsAPI();
    } else {
      data = await this.callRecursiveListingsAPI(params);
    }

    const newData = generateListingsChartData(data);
    this.setState({
      mainChartData: data,
      chartData: {
        x: newData.x,
        y: newData.y,
      },
      isChartLoading: false,
    });
  }

  render() {
    const {
      chartData, chartScope,
      mainChartData, isChartLoading,
    } = this.state;
    const {
      isLocationsTab, slugType,
      topic, width,
      onlyData, isNewsSentiment, hasBigScreenDesign,
    } = this.props;
    return (
      <div className={cx('sentiment_world_chart', { big_screen: hasBigScreenDesign })}>
        <WorldChart
          mainChartData={mainChartData}
          chartData={chartData}
          chartScope={chartScope}
          isLocationsTab={isLocationsTab}
          slugType={slugType}
          topic={topic}
          isChartLoading={isChartLoading}
          onlyData={onlyData}
          isNewsSentiment={isNewsSentiment}
          hasBigScreenDesign={hasBigScreenDesign}
        />
        {width > 1023 && isLocationsTab && (
          <LocationBySentiment
            slugType={slugType}
            topic={topic}
          />
        )}
      </div>
    );
  }
}

SentimentWorldChart.defaultProps = {
  onlyData: false,
};

const mapStateToProps = ({ common, locationsSentiment }, { selectedMainTimePeriod }) => ({
  width: common.width,
  selectedMainTimePeriod: selectedMainTimePeriod ?? locationsSentiment.selectedMainTimePeriod,
});

export default connect(mapStateToProps, null)(SentimentWorldChart);
