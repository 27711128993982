import { connect } from 'react-redux';
import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import autoBind from 'react-autobind';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight';

import withComponentName from '../../../../../decorators/withComponentName';

import Styles from './styles.module.scss';

const DIRECTIONS = {
  LEFT: 0x01,
  RIGHT: 0x02,
};

const cx = classNames.bind(Styles);

export class NavBar extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      leftArrowHidden: true,
      rightArrowHidden: true,
      navListStyles: {
        marginLeft: '0px',
      },
      tabs: props.tabs,
    };

    // distance between navbar items
    this.distanceBetween = 20;
    this.mounted = false;

    this.navContainer = null;
    this.navList = null;
  }

  componentDidMount() {
    this.mounted = true;
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  getSnapshotBeforeUpdate(prevProps) {
    const { tabs } = this.props;
    return prevProps.tabs !== tabs;
  }

  componentDidUpdate(props, state, snapshot) {
    const { tabs, hidden, isChatbotOpened } = this.props;
    if (snapshot) {
      this.setState({
        tabs,
      }, this.onResize);
    } else if (hidden !== props.hidden) {
      this.onResize();
    }
    if (props.isChatbotOpened !== isChatbotOpened) {
      setTimeout(() => {
        this.onResize();
      }, 500);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    this.mounted = false;
  }

  onResize() {
    this.distanceBetween = window.innerWidth >= 1200 ? 20 : 17;

    if (this.mounted) {
      this.setState(({ navListStyles }) => ({
        leftArrowHidden: parseInt(navListStyles.marginLeft, 10) === 0,
        rightArrowHidden: (
          this.navContainer.offsetWidth >= (
            this.navList.offsetWidth + parseInt(navListStyles.marginLeft, 10)
          )
        ),
      }));
    }
  }

  changeTab(selectedTab) {
    this.setState(({ tabs }) => ({
      tabs: tabs.map((tab) => ({
        ...tab,
        active: tab.id === selectedTab.id,
      })),
    }), () => {
      const { onChange } = this.props;
      onChange(selectedTab);
    });
  }

  scroll(direction) {
    const { navListStyles } = this.state;
    switch (direction) {
      case DIRECTIONS.LEFT: {
        let width = 0;

        for (let i = 0; i < this.navList.children.length; i += 1) {
          width += this.navList.children[i].offsetWidth + this.distanceBetween;

          if (-parseInt(navListStyles.marginLeft, 10) - this.navContainer.offsetWidth <= width) {
            width -= this.navList.children[i].offsetWidth + this.distanceBetween;
            break;
          }
        }

        this.setState({
          navListStyles: {
            marginLeft: `${-Math.max(width, 36) + 36}px`,
          },
        });
        break;
      }
      case DIRECTIONS.RIGHT: {
        let width = 0;

        for (let i = 0; i < this.navList.children.length; i += 1) {
          width += this.navList.children[i].offsetWidth + this.distanceBetween;

          if (-parseInt(navListStyles.marginLeft, 10) + this.navContainer.offsetWidth <= width) {
            width -= this.navList.children[i].offsetWidth + this.distanceBetween;
            break;
          }
        }

        this.setState(({
          navListStyles: {
            marginLeft: `${-width + 36}px`,
          },
        }));
        break;
      }
      default:
    }

    this.onResize();
  }

  render() {
    const { tablet, className } = this.props;
    const {
      tabs,
      leftArrowHidden,
      rightArrowHidden,
      navListStyles,
    } = this.state;

    return (
      <div ref={(el) => { this.navContainer = el; }} className={cx('navbar', className, { tablet })}>
        <div hidden={leftArrowHidden} className={Styles['arrow-left']} onClick={() => this.scroll(DIRECTIONS.LEFT)}>
          <FontAwesomeIcon icon={faAngleRight} />
        </div>
        <ul ref={(el) => { this.navList = el; }} style={navListStyles}>
          {tabs.map((tab) => (
            <li
              key={tab.id}
              ref={(el) => { tab.ref = el; }}
              className={cx({ active: tab.active })}
              onClick={() => this.changeTab(tab)}
            >
              <div className={Styles.title}>
                {tab.name}
              </div>
            </li>
          ))}
        </ul>
        <div hidden={rightArrowHidden} className={Styles['arrow-right']} onClick={() => this.scroll(DIRECTIONS.RIGHT)}>
          <FontAwesomeIcon icon={faAngleRight} />
        </div>
      </div>
    );
  }
}

NavBar.defaultProps = {
  onChange: () => {},
  className: '',
  hidden: false,
  tablet: false,
};

NavBar.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    active: PropTypes.bool,
  })).isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
  hidden: PropTypes.bool,
  tablet: PropTypes.bool,
};

const mapStateToProps = ({
  chatbot,
}) => ({
  isChatbotOpened: chatbot.isChatbotOpened,
});

export default connect(mapStateToProps, null)(withComponentName(NavBar));
