import { faLaughBeam as faLaughBeamLight } from '@fortawesome/pro-light-svg-icons/faLaughBeam';
import { faSmile as faSmileLight } from '@fortawesome/pro-light-svg-icons/faSmile';
import { faMeh as faMehLight } from '@fortawesome/pro-light-svg-icons/faMeh';
import { faFrown as faFrownLight } from '@fortawesome/pro-light-svg-icons/faFrown';
import { faAngry as faAngryLight } from '@fortawesome/pro-light-svg-icons/faAngry';

export const periodFilter = [
  { id: 1, label: '1 day', value: '1d' },
  { id: 2, label: '1 week', value: '1w' },
  { id: 3, label: '1 month', value: '1m' },
  { id: 4, label: '1 year', value: '1y' },
  { id: 5, label: 'All', value: 'all' },
];

export const sentimentNavBar = [
  { id: 1, name: 'ALL', value: 'all', active: true },
  { id: 2, name: 'Very Positive', value: 'very_positive' },
  { id: 3, name: 'Positive', value: 'positive' },
  { id: 4, name: 'Neutral', value: 'neutral' },
  { id: 5, name: 'Negative', value: 'negative' },
  { id: 6, name: 'Very Negative', value: 'very_negative' },
];

export const periodTimeValues = {
  '1d': 'day1',
  '1w': 'week1',
  '1m': 'month1',
  '1y': 'month1',
  all: 'month1',
};

export const locationWorldMapColorscale = [
  [0, '#d50000'],
  [0.286, '#d50000'],

  [0.287, '#ff6d00'],
  [0.429, '#ff6d00'],

  [0.430, '#b2b2b2'],
  [0.523, '#b2b2b2'],

  [0.524, '#4caf50'],
  [0.666, '#4caf50'],

  [0.667, '#388e3c'],
  [0.956, '#388e3c'],

  [0.957, '#ffffff'],
  [1, '#ffffff'],
];

export const sentimentLevels = [
  {
    id: 1, title: 'Very Positive', rangeTitle: '+40%...+100%', type: 'very_positive', icon: faLaughBeamLight,
  },
  {
    id: 2, title: 'Positive', rangeTitle: '+10%...+39%', type: 'positive', icon: faSmileLight,
  },
  {
    id: 3, title: 'Neutral', rangeTitle: '-9%...+9%', type: 'neutral', icon: faMehLight,
  },
  {
    id: 4, title: 'Negative', rangeTitle: ' -39%...-10%', type: 'negative', icon: faFrownLight,
  },
  {
    id: 5, title: 'Very Negative', rangeTitle: '-100%...-40%', type: 'very_negative', icon: faAngryLight,
  },
  {
    id: 6, title: 'Insufficient Data', rangeTitle: '', type: 'insufficient',
  },
];

export const SENTIMENT_TOOLTIP_VALUE = 110;
