import { createRef, Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames/bind';
import autoBind from 'react-autobind';
import isEqual from 'lodash-es/isEqual';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import { faInfo } from '@fortawesome/pro-solid-svg-icons/faInfo';

import MainLocationCustomPoint from './MainLocationCustomPoint';
import Preloader from '../../../../Preloader';
import loadable from '../../../../../utils/loadable';

import LocationsSvc from '../../../../../services/dbServices/LocationsSvc';
import { setSelectedLocation, setSelectedActivePoint } from '../../../../../actions/locationsSentiment.actions';
import { sentimentLevels } from '../../../../../data/directory/locations/locationsSentiment';
import { getTrendingBarAssetQuery } from '../../../../../helpers/watchlistHelperFunctions';

import Styles from './styles.module.scss';

const ChartSection = loadable(() => import('./ChartSection'));

const cx = classNames.bind(Styles);

class WorldChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSentimentLevelsOpen: false,
      noDataTooltip: null,
    };

    autoBind(this);

    this.activePointRef = createRef();
  }

  componentDidUpdate(prevProps) {
    const { width, selectedLocationProps, activeLocationPoint } = this.props;
    if (
      width <= 767 && activeLocationPoint && !selectedLocationProps
      && !isEqual(selectedLocationProps, prevProps.selectedLocationProps)
    ) {
      this.setActivePointValue(null);
    }
  }

  componentWillUnmount() {
    this.setSelectedLocationValue(null);
  }

  handleSentimentLevelsClick() {
    this.setState(({ isSentimentLevelsOpen }) => ({
      isSentimentLevelsOpen: !isSentimentLevelsOpen,
    }));
  }

  handleChartModalClose() {
    this.setSelectedLocationValue(null);
  }

  handleLocationRedirect() {
    const { activeLocationPoint } = this.props;
    const {
      entity_type: entityType,
      entity_id: entityId,
      entity_name: entityName,
    } = activeLocationPoint;
    this.setSelectedLocationValue({
      ...activeLocationPoint,
      query: getTrendingBarAssetQuery({ id: entityId, name: entityName }, entityType),
    });
  }

  setActivePointValue(value) {
    const { setSelectedActivePoint: propsSet } = this.props;
    propsSet(value);
  }

  setNoDataTooltip(data) {
    this.setState({
      noDataTooltip: data,
    });
  }

  async setSelectedLocationValue(value) {
    const { setSelectedLocation: propsSetSelectedLocation } = this.props;
    let activeTopic;
    if (value) {
      activeTopic = await LocationsSvc.getCountry(value.entity_id);
      // eslint-disable-next-line no-param-reassign
      value.short_name = activeTopic.short_name;
    }
    propsSetSelectedLocation(value);
  }

  setActivePointRef(value) {
    this.activePointRef.current = value;
  }

  render() {
    const {
      chartData, chartScope, width,
      isLocationsTab, mainChartData,
      isChartLoading, activeLocationPoint,
      selectedLocationProps, onlyData, isNewsSentiment, hasBigScreenDesign,
      isChatbotOpened,
    } = this.props;
    const {
      isSentimentLevelsOpen, noDataTooltip,
    } = this.state;

    let height;
    if (hasBigScreenDesign && width >= 2800) {
      height = 1935;
    } else if (width >= 1024) {
      height = 675;
    } else if (width >= 768) {
      height = 400;
    } else {
      height = 210;
    }

    if (onlyData) {
      if (width >= 2800) {
        height = 893;
      } else {
        height = 300;
      }
    }

    return (
      <div
        style={{ height: `${height}px` }}
        className={cx('sentiment_world_chart', {
          location_tab: isLocationsTab,
          news_sentiment: isNewsSentiment,
          big_screen: hasBigScreenDesign,
        })}
      >
        {!onlyData && (
          <div className={Styles.sentiment_levels_holder}>
            <div
              className={Styles.sentiment_levels_btn}
              onClick={this.handleSentimentLevelsClick}
            >
              <FontAwesomeIcon icon={isSentimentLevelsOpen ? faXmark : faInfo} />
            </div>
            <div className={cx('sentiment_levels', { open: isSentimentLevelsOpen })}>
              <div className={Styles.levels_title}>Sentiment Levels</div>
              <div className={Styles.levels_holder}>
                {sentimentLevels.map(({
                  id, title, rangeTitle, type, icon,
                }) => (
                  <div key={id} className={cx('level_row', type)}>
                    <div className={Styles.row_icon}>
                      {icon && <FontAwesomeIcon icon={icon} />}
                    </div>
                    <div>
                      {title}
                    </div>
                    <div className={Styles.row_range}>
                      {rangeTitle}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {isChartLoading ? (
          <Preloader
            loading
            centered
          />
        ) : (
          <ChartSection
            mainChartData={mainChartData}
            setSelectedLocationValue={this.setSelectedLocationValue}
            setActivePointValue={this.setActivePointValue}
            chartData={chartData}
            chartScope={chartScope}
            width={width}
            activePointRef={this.activePointRef}
            setNoDataTooltip={this.setNoDataTooltip}
            height={height}
            onlyData={onlyData}
            isNewsSentiment={isNewsSentiment}
            isChatbotOpened={isChatbotOpened}
          />
        )}
        <MainLocationCustomPoint
          activeLocationPoint={activeLocationPoint}
          handleLocationRedirect={this.handleLocationRedirect}
          width={width}
          selectedTopic={selectedLocationProps}
          handleChartModalClose={this.handleChartModalClose}
          setActivePointRef={this.setActivePointRef}
          noDataTooltip={noDataTooltip}
          hasBigScreenDesign={hasBigScreenDesign}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ common, locationsSentiment, chatbot }) => ({
  width: common.width,
  selectedLocationProps: locationsSentiment.selectedLocation,
  activeLocationPoint: locationsSentiment.activeLocationPoint,
  isChatbotOpened: chatbot.isChatbotOpened,
});

const mapDispatchToProps = {
  setSelectedLocation, setSelectedActivePoint,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WorldChart));
