import { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faLanguage as faLanguageRegular } from '@fortawesome/pro-regular-svg-icons/faLanguage';
import { faLanguage as faLanguageLight } from '@fortawesome/pro-light-svg-icons/faLanguage';
import { useSelector } from 'react-redux';
import { calculateTimeAgo } from '../../../../../../../../helpers/helpers';
import { getAverageSentimentStoryText } from '../../../../../../../../helpers/sentimentHelpers';
import defaultStoryImg from '../../../../../../../../../../assets/images/author_default_logo.png';
import { GOLD_PLAN } from '../../../../../../../../data/subscriptionPlans';

import Tooltip from '../../../../../../../Tooltip';

import Styles from '../styles.module.scss';
import StoriesSvc from '../../../../../../../../services/StoriesSvc';

const cx = classNames.bind(Styles);

const ChartStories = (props) => {
  const { story, width, hasBigScreenDesign } = props;
  const [storyImage, setStoryImage] = useState(defaultStoryImg);
  const [showScorePopover, setShowScorePopover] = useState(false);

  const {
    translate_all: showTranslation,
    translationSupportedLanguages,
  } = useSelector((state) => state.storyFilters);

  const [currentIcon, setCurrentIcon] = useState(faLanguageLight);
  const [isTranslationShown, setIsTranslationShow] = useState(showTranslation);
  const [translation, setTranslation] = useState({
    title: story?.translations?.[0]?.title,
    description: story?.translations?.[0]?.description,
  });

  const setTranslations = async () => {
    if (!story?.translations?.length) {
      const isTranslationSupported = translationSupportedLanguages?.map((el) => el.code).toString().split(',')?.includes(story.lang);
      if (!story?.translations?.length && isTranslationSupported) {
        const translatedData = await StoriesSvc.getTranslatedStory(story.uuid, 'en');
        story.translations = translatedData?.data?.article_translations;
        if (!story.translations?.length) {
          return;
        }
        setTranslation({
          title: story?.translations[0]?.title,
          description: story?.translations[0]?.description,
        });
      }
    }
    if (story?.translations !== null && story?.translations && story?.translations?.length) {
      setTranslation({
        title: story?.translations[0]?.title,
        description: story?.translations[0]?.description,
      });
    }
  };

  const mouseEnterHandler = () => {
    setCurrentIcon(faLanguageRegular);
  };

  const mouseLeaveHandler = () => {
    setCurrentIcon(faLanguageLight);
  };

  useEffect(() => {
    const checkImageLoading = (imageUrl, load, notLoad) => {
      const img = new Image();

      img.onload = load;
      img.onerror = notLoad;

      img.src = imageUrl;
    };

    const loadStoryImage = () => {
      let images = [story.author_image_url, story.domain_cached_logo_url];

      if (story.type !== 'Tweet') {
        images = images.reverse();
      }

      checkImageLoading(images[0], () => {
        setStoryImage(images[0]);
      }, () => {
        checkImageLoading(images[1], () => {
          setStoryImage(images[1]);
        }, () => {
          setStoryImage(defaultStoryImg);
        });
      });
    };

    loadStoryImage();
  }, []);

  useEffect(() => {
    const isTranslationSupported = translationSupportedLanguages?.map((el) => el.code).toString().split(',')?.includes(story.lang);
    if (isTranslationSupported) {
      setTranslations();
    }
    setIsTranslationShow(showTranslation);
  }, [showTranslation, story.translations]);

  const toggleTranslation = (e) => {
    e.stopPropagation();
    const newState = !isTranslationShown;

    if (translation.title) {
      setIsTranslationShow(newState);
    }
  };

  const togglePopOver = (e) => {
    e.stopPropagation();
    setShowScorePopover(!showScorePopover);
  };

  const openStory = (e) => {
    if (e) e.stopPropagation();
    const url = story.url || story.expanded_url || (story.type === 'Tweet' ? `https://twitter.com/i/web/status/${story.additional_data.tweet_id}` : '');
    const isSingleStoryUrl = story.lowest_premium_plan === GOLD_PLAN && story.type !== 'Tweet';
    const mainURL = isSingleStoryUrl || !url ? `/news/stories/${story.uuid_title}` : url;
    window.open(mainURL, '_blank')?.focus?.();
  };

  const sentimentValue = (story.sentiment || story.sentiment === 0)
    && getAverageSentimentStoryText(story.sentiment);

  return (
    <div className={Styles.chart_storie_row}>
      <div className={Styles.chart_storie_title} onClick={openStory}>
        {(isTranslationShown && translation?.title) ? translation?.title : story.title}
      </div>
      <div className={Styles.chart_storie_footer}>
        <img src={storyImage} className={Styles.footer_source_image} alt="" />
        {story.type === 'Tweet' ? (
          <>
            <FontAwesomeIcon className={cx('twitter_icon')} icon={faTwitter} />
            <span className={Styles.twitter_handle}>
              @
              {story.author_screen_name}
            </span>
          </>
        ) : (
          <span className={Styles.domain_name}>
            {story.domain_name}
          </span>
        )}
        <span className={Styles.publish_time}>
          {calculateTimeAgo(story.publishTime, width <= 1500)}
        </span>
        <Tooltip
          renderOutside
          contentStyle={{
            transform: 'translate(-50%, calc(-100% - 5px))',
          }}
          allowTransformReplace={false}
          icon={(
            <span className={cx('score-value')}>
              {Math.round(story.score)}
              %
            </span>
          )}
          className={cx('story_score', {
            'with-smile-tooltip': sentimentValue,
            'show-popover': showScorePopover,
          })}
          tooltipClassName={cx('story_score_tooltip')}
          hasBigScreenDesign={hasBigScreenDesign}
        >
          CityFALCON Score showing relevance&nbsp;
          of story to you and your chosen topics.
          <a href="https://www.cityfalcon.ai/features/cf-score" rel="noopener noreferrer">
            <span>How does it work?</span>
          </a>
        </Tooltip>
        {showScorePopover && (
          <span className={cx('popover_backdrop')} onClick={togglePopOver} />
        )}
        {/** Translate */}
        {(showTranslation && translationSupportedLanguages?.map((el) => el.code)
          .toString().split(',')?.includes(story.lang) && story.lang !== 'en') && (
          <div className={cx('news-card-translation')}>
            <span
              className={cx('details-header__info--translate')}
              onMouseEnter={mouseEnterHandler}
              onMouseLeave={mouseLeaveHandler}
            >
              <Tooltip
                icon={(<FontAwesomeIcon icon={currentIcon} />)}
                hasBigScreenDesign
                isBigScreen
                dynamicPosition
              >
                <span className={cx('title')}>
                  {(() => {
                    if (translation && translation.title) {
                      if (isTranslationShown) {
                        return (
                          <>
                            <div className={cx('translated--from--title')}>
                              {`Translated from ${translationSupportedLanguages
                                ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                            </div>
                            <div
                              className={cx('translate--title')}
                              onClick={toggleTranslation}
                            >
                              Show Original
                            </div>
                          </>
                        );
                      }
                      return (
                        <>
                          <div className={cx('translated--from--title')}>
                            {`Original in ${translationSupportedLanguages
                              ?.filter((el) => el?.code === story?.lang)[0]?.name}.`}
                          </div>
                          <div
                            className={cx('translate--title')}
                            onClick={toggleTranslation}
                          >
                            Translate to English
                          </div>
                        </>
                      );
                    }
                    return 'Translating to English...';
                  })()}
                </span>
              </Tooltip>
            </span>
          </div>
        )}
        {sentimentValue && (
          <Tooltip
            renderOutside
            contentStyle={{
              transform: 'translate(-50%, calc(-100% - 5px))',
            }}
            allowTransformReplace={false}
            icon={<FontAwesomeIcon icon={sentimentValue.icon} />}
            className={cx('statistics_sentiment', sentimentValue.type)}
            tooltipClassName={cx('story_sentiment_tooltip')}
            hasBigScreenDesign={hasBigScreenDesign}
          >
            Sentiment:
            {' '}
            {story.sentiment > 0 ? `+${story.sentiment}` : story.sentiment}
            %
            <br />
            Ranges from -100% to +100%.
            {' '}
            <a
              href="https://www.cityfalcon.ai/features/sentiment"
              target="_blank"
              rel="noopener noreferrer"
              className={cx('sentiment-popover-link')}
            >
              <span className={cx('sentiment-popover-learn-more')}>Learn more</span>
            </a>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default ChartStories;
