import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import autoBind from 'react-autobind';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';

import ChartStories from './ChartStories';
import ModalSummaries from '../../../../../Charts/StoriesModal/StoriesModalDesktop/ModalSummaries';
import Preloader from '../../../../../../Preloader';
import Tooltip from '../../../../../../Tooltip';

import SentimentsSvc from '../../../../../../../services/dbServices/SentimentsSvc';
import { getAverageSentimentText } from '../../../../../../../helpers/sentimentHelpers';
import { getTrendingBarAssetQuery } from '../../../../../../../helpers/watchlistHelperFunctions';
import convertPermalinkToPath from '../../../../../../../helpers/urlHelpers';

import { periodTimeValues } from '../../../../../../../data/directory/locations/locationsSentiment';
import StoriesSvc from '../../../../../../../services/StoriesSvc';
import EntitiesSvc from '../../../../../../../services/dbServices/EntitiesSvc';
import trendingStoriesParams from '../../../../../../../data/trendingStoriesParams';
import { GOLD } from '../../../../../../../data/permissions';
import dummySummaries from '../../../../../../../data/summaries/dummySummaries';
import { getFlag } from '../../../../../../../helpers/topicsHelpers';
import { getSentimentRange } from '../../../../../../../helpers/chartHelpers';
import { removeDuplicates } from '../../../../../../../helpers/removeDuplicates';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

class LocationChartModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sentimentLabel: {},
      isSubLocationLoading: true,
      subLocationsList: [],
      isStoryLoading: true,
      storiesArr: [],
      activeTab: 'stories',
      summaryData: {
        loading: true,
        summaries: [],
        notAllowed: false,
      },
      hasTitleTooltip: false,
      firstTimeRowsScroll: true,
    };
    autoBind(this);

    this.sentimentRowsRef = createRef();
  }

  componentDidMount() {
    this.generateModalData();
  }

  componentDidUpdate(prevProps) {
    const { selectedTopic, selectedMainTimePeriod } = this.props;
    if (
      selectedTopic.entity_id !== prevProps.selectedTopic.entity_id
      || selectedMainTimePeriod !== prevProps.selectedMainTimePeriod
    ) {
      this.setState({
        activeTab: 'stories',
        summaryData: {
          loading: true,
          summaries: [],
          notAllowed: false,
        },
        hasTitleTooltip: false,
      });
      this.generateModalData();
    }
  }

  onPermalinkClick(e, topic = null) {
    const { selectedTopic, history } = this.props;
    // eslint-disable-next-line no-param-reassign
    topic ??= selectedTopic;

    if (topic.permalink) {
      return;
    }

    e.preventDefault();
    EntitiesSvc.getEntityPermalink({
      entityId: topic.entity_id,
      entityType: topic.entity_type,
    }).then(({ permalink }) => {
      if (permalink) {
        topic.permalink = permalink;
        history.push(convertPermalinkToPath(permalink));
      }
    });
  }

  getTabBodyHeight() {
    const { width } = this.props;
    const sentimentRowHeight = this.sentimentRowsRef.current?.clientHeight || 0;
    let tabHeight = 338;
    if (width >= 2800) {
      tabHeight = 676 + (220 - sentimentRowHeight);
    } else {
      tabHeight = 338 + (110 - sentimentRowHeight);
    }
    return tabHeight;
  }

  scrollTo(offset, callback) {
    const fixedOffset = offset.toFixed();
    // eslint-disable-next-line func-names
    const onScroll = function () {
      if (window.pageYOffset.toFixed() === fixedOffset) {
        window.removeEventListener('scroll', onScroll);
        callback();
      }
    };

    window.addEventListener('scroll', onScroll);
    onScroll();
    this.sentimentRowsRef.current.scrollTo({
      top: offset,
      behavior: 'smooth',
    });
  }

  delayScroll(start, end, duration) {
    const delta = end - start;
    let startTime;
    if (window.performance && window.performance.now) {
      startTime = performance.now();
    } else if (Date.now) {
      startTime = Date.now();
    } else {
      startTime = new Date().getTime();
    }

    const easing = (x, t, b, c, d) => {
      if ((t /= d / 2) < 1) return c / 2 * t * t + b;
      return -c / 2 * ((--t) * (t - 2) - 1) + b;
    };

    const delayScrollLoop = (time) => {
      const t = (!time ? 0 : time - startTime);
      const factor = easing(null, t, 0, 1, duration);
      this.sentimentRowsRef.current.scrollTop = start + delta * factor;
      if (t < duration && this.sentimentRowsRef.current.scrollTop !== end) {
        requestAnimationFrame(delayScrollLoop);
      }
    };
    delayScrollLoop();
  }

  autoListScroll() {
    const { firstTimeRowsScroll } = this.state;
    if (!firstTimeRowsScroll) return;
    this.setState({
      firstTimeRowsScroll: false,
    });
    setTimeout(() => {
      this.delayScroll(0, 40, 700);
      setTimeout(() => {
        this.delayScroll(40, 0, 700);
      }, 1600);
    }, 1000);
  }

  async callListingsAPI(params) {
    try {
      const { data } = await SentimentsSvc.getListingsSentiments(params);
      return data;
    } catch (e) {
      console.log(e);
    }
    return { };
  }

  generateModalData() {
    const { selectedTopic, width } = this.props;
    const { sentiment } = selectedTopic;
    const sentimentNumber = sentiment ? sentiment.replace('%', '') : null;
    this.setState({
      sentimentLabel: getAverageSentimentText(sentimentNumber),
    });
    this.generateSubLocationsData();
    if (width > 1023) {
      this.generateStoryData();
    }
  }

  async generateSubLocationsData() {
    const { selectedMainTimePeriod, selectedTopic } = this.props;
    const { entity_type: entityType, entity_id: entityId, value } = selectedTopic;
    const params = {
      period: selectedMainTimePeriod,
      selection_mode: 'children',
      entity_type: entityType,
      entity_id: entityId,
      per_page: 5,
      sorting: value < 0 ? 'ascending' : 'descending',
      sentiment_filter_mode: 'range',
    };
    this.setState({
      isSubLocationLoading: true,
      subLocationsList: [],
    });
    const data = await this.callListingsAPI(params);
    const subLocationsList = (data.list || []).map((list) => {
      const sentimentNumber = (list.sentiment || '').replace('%', '');
      return {
        ...list,
        ...getAverageSentimentText(sentimentNumber),
      };
    });
    this.setState({
      subLocationsList,
      isSubLocationLoading: false,
    }, () => {
      this.autoListScroll();
    });
  }

  async generateStoryData() {
    const {
      selectedTopic,
      earliestSearchDate,
      selectedMainTimePeriod,
      storyFilters,
    } = this.props;

    const { entity_type: entityType, entity_id: entityId, entity_name: entityName } = selectedTopic;
    const query = getTrendingBarAssetQuery({ id: entityId, name: entityName }, entityType);
    const sentimentRange = getSentimentRange(Number(selectedTopic.value));

    const params = {
      query,
      categories: 'mp,op,r',
      min_score: 5,
      order_by: 'top',
      time_filter: periodTimeValues[selectedMainTimePeriod],
      limit: 20,
      fold_similar_stories: true,
      all_languages: storyFilters.all_languages,
      ...(!storyFilters.all_languages && {
        languages: storyFilters.languages,
      }),
      sentiment_gte: sentimentRange.sentiment_gte,
      sentiment_lte: sentimentRange.sentiment_lte,
    };
    this.setState({
      isStoryLoading: true,
      storiesArr: [],
    });
    let { stories } = await StoriesSvc.getStories(params, earliestSearchDate);
    if (stories.length < 5) {
      const fetchedStories = stories;
      const newRequests = [];
      const newStoriesParams = {
        ...params,
        all_languages: true,
        languages: undefined,
      };
      if (sentimentRange.sentiment_lte !== 100) {
        newRequests.push(StoriesSvc.getStories({
          ...newStoriesParams,
          sentiment_gte: sentimentRange.sentiment_lte,
          sentiment_lte: 100,
        }));
      }
      if (sentimentRange.sentiment_gte !== -100) {
        newRequests.push(StoriesSvc.getStories({
          ...newStoriesParams,
          sentiment_gte: -100,
          sentiment_lte: sentimentRange.sentiment_gte,
        }));
      }
      const storiesDataArr = await Promise.all(newRequests);
      stories = storiesDataArr.flatMap(({ stories: newStories, notAllowed }) => (
        !notAllowed ? newStories : []
      ));

      stories = removeDuplicates(
        [
          ...fetchedStories,
          ...stories,
        ],
        'id',
      );
    }

    this.setState({
      storiesArr: stories.slice(0, 5),
      isStoryLoading: false,
    });
  }

  async generateSummaryData() {
    const {
      isGold, selectedMainTimePeriod, selectedTopic, maxSummarySize,
      earliestSearchDate,
    } = this.props;
    if (!isGold) {
      this.setState({
        summaryData: {
          loading: false,
          summaries: dummySummaries,
          notAllowed: false,
        },
      });
      return;
    }
    const { entity_type: entityType, entity_id: entityId, entity_name: entityName } = selectedTopic;
    const query = getTrendingBarAssetQuery({ id: entityId, name: entityName }, entityType);
    let summariesFetchingInstance = null;
    const categories = 'mp,op,r';
    const params = {
      ...trendingStoriesParams,
      categories,
      query,
      time_filter: periodTimeValues[selectedMainTimePeriod],
    };

    const { notAllowed, summariesKey } = await StoriesSvc.getStories(
      {
        ...params,
        only_summaries: true,
        number_of_summaries: maxSummarySize,
        summarization_coverage: query.split(':').slice(1, 2).join('#'),
        limit: 500,
        fold_similar_stories: false,
        languages: 'en',
        all_languages: false,
      },
      earliestSearchDate,
    );

    if (notAllowed) {
      this.setState({
        summaryData: {
          loading: false,
          summaries: [],
          notAllowed,
        },
      });
      return;
    }

    if (summariesKey) {
      summariesFetchingInstance = StoriesSvc.waitSummaries(summariesKey);
      const summaries = await summariesFetchingInstance.promise;
      this.setState({
        summaryData: {
          loading: false,
          summaries,
          notAllowed: false,
        },
      });
    } else {
      this.setState({
        summaryData: {
          loading: false,
          summaries: [],
          notAllowed: false,
        },
      });
    }
  }

  handleActiveTabChange(value) {
    this.setState({
      activeTab: value,
    });
    if (value === 'summary') {
      this.generateSummaryData();
    }
  }

  handleTitleMouseEnter(refValue) {
    if (!refValue) return;
    this.setState({
      hasTitleTooltip: refValue.target.offsetWidth < refValue.target.scrollWidth,
    });
  }

  render() {
    const {
      selectedTopic,
      handleChartModalClose,
      width,
      isGold,
      hasBigScreenDesign,
    } = this.props;
    const {
      sentimentLabel, activeTab, isSubLocationLoading, subLocationsList,
      isStoryLoading, storiesArr, summaryData, hasTitleTooltip,
    } = this.state;

    const maxTabHeight = this.getTabBodyHeight();

    const noData = !isSubLocationLoading && !subLocationsList.length
      && !isStoryLoading && !storiesArr.length;

    const pageLink = selectedTopic.permalink ? convertPermalinkToPath(selectedTopic.permalink) : '#';
    const flagIcon = selectedTopic
      ? getFlag({ ...selectedTopic, name: selectedTopic.entity_name }) : null;

    return (
      <div className={cx('world_map_modal', { big_screen: hasBigScreenDesign })}>
        <div className={Styles.world_map_modal_close} onClick={handleChartModalClose}>
          <FontAwesomeIcon icon={faXmark} />
        </div>
        <div className={Styles.map_modal_header}>
          {flagIcon && (
            <img
              className={Styles.flag}
              src={flagIcon}
              alt="Flag"
            />
          )}
          <Tooltip
            className={cx('header_title_holder', { not_active: !hasTitleTooltip })}
            tooltipIconClassName={Styles.header_title_icon}
            hasBigScreenDesign={hasBigScreenDesign}
            icon={(
              <Link
                to={pageLink}
                className={Styles.header_title}
                onMouseEnter={this.handleTitleMouseEnter}
                onClick={this.onPermalinkClick}
              >
                {selectedTopic.entity_name}
              </Link>
            )}
          >
            {selectedTopic.entity_name}
          </Tooltip>
          <div className={cx('header_sentiment', sentimentLabel.type)}>
            {sentimentLabel.storyText || ''}
          </div>
        </div>
        <div className={cx('map_modal_body', { no_summary: !isGold && activeTab === 'summary' })}>
          <div className={Styles.map_sentiment_title}>
            Sentiment By States
          </div>
          <div className={Styles.map_sentiment_rows} ref={this.sentimentRowsRef}>
            {isSubLocationLoading && (
              <Preloader
                loading
                static
                centered
              />
            )}
            {!isSubLocationLoading && !subLocationsList.length && (
              <div className={Styles.map_sentiment_no_data}>
                No recent sentiment data is available
              </div>
            )}
            {subLocationsList.map((list) => (
              <MapSentimentRow
                key={list.entity_id}
                onClick={(e) => this.onPermalinkClick(e, list)}
                list={list}
              />
            ))}
          </div>
          <div className={Styles.map_news_title}>
            <div
              className={cx('map_news_title_tab', { active: activeTab === 'stories' })}
              onClick={() => this.handleActiveTabChange('stories')}
            >
              Top Stories
            </div>
            <div
              className={cx('map_news_title_tab', { active: activeTab === 'summary' })}
              onClick={() => this.handleActiveTabChange('summary')}
            >
              Key Headlines
            </div>
          </div>
          <div
            className={Styles.map_news_tab_holder}
            style={{
              maxHeight: `${maxTabHeight}px`,
            }}
          >
            {activeTab === 'stories' && (
              <div className={Styles.map_news_stories}>
                {isStoryLoading && !storiesArr.length && (
                  <Preloader
                    loading
                    static
                    centered
                  />
                )}
                {!isStoryLoading && !storiesArr.length && (
                  <div className={Styles.map_news_no_data}>
                    No content was found that meets your criteria
                  </div>
                )}
                {storiesArr.map((story) => (
                  <ChartStories
                    key={story.id}
                    story={story}
                    width={width}
                    hasBigScreenDesign={hasBigScreenDesign}
                  />
                ))}
              </div>
            )}
            {activeTab === 'summary' && (
              <div className={Styles.map_summaries}>
                <ModalSummaries
                  isSentimentMainLocations
                  summaryData={summaryData}
                  hasBigScreenDesign={hasBigScreenDesign}
                />
              </div>
            )}
          </div>
        </div>
        {
          !noData && (
            <div className={Styles.map_modal_show_more}>
              <Link
                to={pageLink}
                onClick={this.onPermalinkClick}
              >
                See More
                <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </div>
          )
        }
      </div>
    );
  }
}

const MapSentimentRow = ({ list, onClick }) => {
  const {
    entity_name: name, sentiment, icon, type, permalink,
  } = list;
  const pageLink = permalink ? convertPermalinkToPath(permalink) : '#';

  return (
    <Link to={pageLink} className={Styles.map_sentiment_row} onClick={onClick}>
      <div className={Styles.map_sentiment_text}>
        {name}
      </div>
      <div className={cx('map_sentiment_value', type)}>
        <FontAwesomeIcon icon={icon} />
        {sentiment}
      </div>
    </Link>
  );
};

const mapStateToProps = ({
  locationsSentiment, subscriptions, common, storyFilters,
}) => ({
  selectedMainTimePeriod: locationsSentiment.selectedMainTimePeriod,
  earliestSearchDate: subscriptions.permissions.earliest_search_date,
  isGold: subscriptions.permissions.access_levels.includes(GOLD),
  maxSummarySize: subscriptions.permissions.max_summary_size,
  width: common.width,
  storyFilters,
});

export default connect(mapStateToProps, null)(withRouter(LocationChartModal));
