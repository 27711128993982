import { useState, memo } from 'react';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LocationChartModal from './LocationChartModal';

import Styles from '../styles.module.scss';

const cx = classNames.bind(Styles);

const MainLocationCustomPoint = (props) => {
  const {
    activeLocationPoint, handleLocationRedirect, setActivePointRef,
    width, selectedTopic, handleChartModalClose, noDataTooltip,
    hasBigScreenDesign,
  } = props;

  const {
    xaxisPoint, yaxisPoint, location, text, type, icon, isNoData,
  } = activeLocationPoint || noDataTooltip || { };

  const [activePointWidth, setActivePointWidth] = useState(150);
  const [outSideScreen, setOutSideScreen] = useState(false);
  const [outSideScreenPoint, setOutSideScreenPoint] = useState(0);

  const setPointRef = (value) => {
    setActivePointRef(value);
    if (!value) return;
    const pointWidth = value.clientWidth || 150;
    setActivePointWidth(pointWidth);
    if (width > 767) {
      const paddingSpace = width > 1023 ? 60 : 40;
      if (xaxisPoint + pointWidth + paddingSpace >= width) {
        setOutSideScreen(true);
        const innerPadding = width > 1023 ? 113 : 40;
        setOutSideScreenPoint(width - innerPadding - (pointWidth / 2) - 15);
      } else if ((xaxisPoint - (pointWidth / 2)) < 0) {
        setOutSideScreen(true);
        setOutSideScreenPoint(Math.round(pointWidth / 2));
      } else {
        setOutSideScreen(false);
        setOutSideScreenPoint(0);
      }
    }
  };

  const handleTooltipClick = () => {
    if (!isNoData) handleLocationRedirect();
  };

  const leftPoint = outSideScreen ? outSideScreenPoint : xaxisPoint;

  return (
    <>
      {
        (activeLocationPoint || noDataTooltip) && (
          <div
            className={cx('chart_active_point_holder', 'main_location', type, {
              no_data: isNoData,
              has_big_tooltip:
                width <= 767 && xaxisPoint + activePointWidth >= width,
            })}
            style={{
              top: `${yaxisPoint}px`,
              left: `${leftPoint}px`,
            }}
            ref={setPointRef}
            onClick={handleTooltipClick}
          >
            <div className={Styles.chart_active_point}>
              <div className={Styles.active_point_location}>{location}</div>
              {!isNoData && (
                <>
                  {icon && <FontAwesomeIcon icon={icon} />}
                  <div className={Styles.active_point_text}>
                    {text}
                    %
                  </div>
                </>
              )}
            </div>
          </div>
        )
      }
      {
        width > 767 && selectedTopic && (
          <LocationChartModal
            selectedTopic={selectedTopic}
            handleChartModalClose={handleChartModalClose}
            hasBigScreenDesign={hasBigScreenDesign}
          />
        )
      }
    </>
  );
};

export default memo(MainLocationCustomPoint);
